/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    ul: "ul",
    li: "li",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "In diesem Produktfilm für die ", React.createElement(_components.strong, null, "TechnoTeam Bildverarbeitung GmbH"), " möchten wir die LMK position im besten Licht präsentieren."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Es soll ein kurzweiliger Film sein, der die Zielgruppe der Displayhersteller, d.h. Ingenieure und andere Entscheider, wie Geschäftsführer, gleichermaßen anspricht."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Besondere Merkmale: Wir blenden die Highlights ein, während man den Roboter bei der täglichen Arbeit sieht: Messungen an Displays durchführen."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Fun Fact: Die spezielle Kamera sitzt auf einem Roboterarm, der etwas ganz Besonderes ist: Er ist der Einzige, der mattschwarz lackiert ist. Andere Industrieroboter sind in der Regel weiß. Eine helle Farbe könnte die Messung durch Reflexion beeinträchtigen.")), "\n"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
